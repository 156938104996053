import React, { ReactNode, useEffect, useState } from 'react';

import * as RadixTooltip from '@radix-ui/react-tooltip';
import { cn } from '@ui/uikit/utils';

export type TooltipContentProps<T = ReactNode> =
  | { caption: T }
  | { title: T; message?: T }
  | { message: T; title?: T };

export type TooltipProps<T = ReactNode> = Pick<RadixTooltip.TooltipContentProps, 'sideOffset'> & {
  contentClassName?: string;
  trigger: ReactNode;
  openOnClick?: boolean;
  autoCloseDelay?: number;
  onTriggerClick?: () => void;
  side?: 'bottom' | 'top' | 'left' | 'right';
} & TooltipContentProps<T>;

export const Tooltip = ({
  contentClassName,
  sideOffset = 4,
  trigger,
  openOnClick = false,
  autoCloseDelay = 2000,
  side = 'bottom',
  onTriggerClick,
  ...props
}: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isCaption = 'caption' in props && !!props.caption;
  const isMessage = ('message' in props && !!props.message) || ('title' in props && !!props.title);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (isOpen && openOnClick) {
      timer = setTimeout(() => setIsOpen(false), autoCloseDelay);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isOpen, autoCloseDelay, openOnClick]);

  const handleClick = () => {
    if (onTriggerClick) {
      onTriggerClick();
    }

    if (openOnClick) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <RadixTooltip.Provider delayDuration={openOnClick ? 0 : 200}>
      <RadixTooltip.Root open={isOpen} onOpenChange={openOnClick ? undefined : setIsOpen}>
        <RadixTooltip.Trigger onClick={handleClick} asChild>
          {trigger}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={cn(
              contentClassName,
              isCaption ? 'tooltip-caption-a' : 'tooltip-message-a',
              'tooltip-animate',
            )}
            sideOffset={sideOffset}
            side={side}
            alignOffset={-29}
            collisionPadding={{ left: -13 }}
          >
            {isCaption && props.caption}

            {isMessage && (
              <>
                {props.title && <h5 className="tooltip-message-title">{props.title}</h5>}
                {props.message && <p>{props.message}</p>}
              </>
            )}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
