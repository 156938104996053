import React, { PropsWithChildren } from 'react';

import classNames from 'classnames';

type HeadingTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
type CaptionTags =
  | 'caption-m'
  | 'caption-m-strong'
  | 'caption-m-mono'
  | 'caption-s'
  | 'caption-s-strong'
  | 'caption-s-mono'
  | 'caption-xs'
  | 'caption-xs-strong'
  | 'caption-xss'
  | 'caption-xss-strong';

export type TypographyVariants = HeadingTags | CaptionTags | 'display';

export type TypographyColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'pink'
  | 'purple'
  | 'blue'
  | 'orange'
  | 'green'
  | 'red'
  | 'white';

const TYPOGRAPHY_STYLES: Record<TypographyVariants, { base?: string; desktop?: string }> = {
  display: {
    base: 'text-[28px] leading-[32px] font-semibold',
    desktop: 'xl-msq:text-[40px] xl-msq:leading-[44px]',
  },
  h1: {
    base: 'text-[24px] leading-[28px] font-semibold',
    desktop: 'xl-msq:text-[32px] xl-msq:leading-[36px]',
  },
  h2: {
    base: 'text-[20px] leading-[24px] font-semibold',
    desktop: 'xl-msq:text-[28px] xl-msq:leading-[32px]',
  },
  h3: {
    base: 'text-[18px] leading-[22px] font-semibold',
    desktop: 'xl-msq:text-[24px] xl-msq:leading-[28px]',
  },
  h4: {
    base: 'text-[16px] leading-[20px] font-semibold',
    desktop: 'xl-msq:text-[20px] xl-msq:leading-[24px]',
  },
  h5: {
    base: 'text-[14px] leading-[20px] font-semibold',
    desktop: 'xl-msq:text-[16px] xl-msq:leading-[20px]',
  },
  'caption-m': {
    base: 'text-[16px] leading-[20px] font-medium',
  },
  'caption-m-strong': {
    base: 'text-[16px] leading-[20px] font-semibold',
  },
  'caption-m-mono': {
    base: 'text-[16px] leading-[20px] font-lining-nums font-tabular-nums font-medium',
  },
  'caption-s': {
    base: 'text-[14px] leading-[20px] font-medium',
  },
  'caption-s-strong': {
    base: 'text-[14px] leading-[20px] font-semibold',
  },
  'caption-s-mono': {
    base: 'text-[14px] leading-[20px] font-lining-nums font-tabular-nums font-medium',
  },
  'caption-xs': {
    base: 'text-[13px] leading-[16px] font-medium',
  },
  'caption-xs-strong': {
    base: 'text-[13px] leading-[16px] font-semibold',
  },
  'caption-xss': {
    base: 'text-[11px] leading-[14px] font-medium',
  },
  'caption-xss-strong': {
    base: 'text-[11px] leading-[14px] font-semibold font-medium',
  },
};

export interface TypographyProps {
  /**
   * Color applied to content
   * @default primary
   */
  color?: TypographyColors;
  /**
   * Text ellipsis
   * @default false
   */
  isEllipsis?: boolean;
  /**
   * Define text transformation applied to content
   */
  transform?: 'capitalize' | 'lowercase' | 'uppercase';
  /**
   * Defines the typography style variant to apply
   * @default caption-m
   */
  variant?: TypographyVariants;
}

export const Typography = ({
  children,
  color = 'primary',
  isEllipsis = false,
  variant = 'caption-m',
  transform,
}: PropsWithChildren<TypographyProps>) => {
  const { base, desktop } = TYPOGRAPHY_STYLES[variant];

  const isCaptionOrDisplay = variant.startsWith('caption') || variant === 'display';
  const Component = isCaptionOrDisplay ? 'span' : (variant as HeadingTags);

  return (
    <Component
      className={classNames(base, desktop, `text-base-text-${color}`, {
        'truncate block': isEllipsis,
        [transform || '']: transform,
      })}
    >
      {children}
    </Component>
  );
};
